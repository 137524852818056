var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('b-tabs',[_c('b-tab',{attrs:{"title":"English"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('form-wizard',{ref:"podcast",attrs:{"color":"#556ee6","enctype":"multipart/form-data","finishButtonText":this.$route.name == 'add-slider'
                    ? 'Save Data'
                    : 'Update Data',"back-button-text":"Go Back!","next-button-text":"Go Next!"},on:{"on-complete":_vm.submitData}},[_c('tab-content',{attrs:{"title":"Slider Details","icon":"mdi mdi-account-details","before-change":() => _vm.validateFormOne()}},[_c('b-form-group',{attrs:{"id":"input-group-1"}},[_c('label',{attrs:{"for":"input-1"}},[_vm._v("Slider Title "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-input',{class:{
                        'is-invalid': _vm.submitted && _vm.$v.form.title.$invalid,
                      },attrs:{"id":"input-1","placeholder":"Enter Slider Title"},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}}),(_vm.submitted && !_vm.$v.form.title.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Slider Title is required. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"position-relative",attrs:{"id":"input-group-2","label":"Tell Us More About The Slider","label-for":"input-2"}},[_c('ckeditor',{attrs:{"editor":_vm.editor},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1),_c('div',{staticClass:"row"},[_c('b-form-group',{staticClass:"col-6"},[_c('label',{attrs:{"for":"input-2"}},[_vm._v("Type "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-select',{class:{
                          'is-invalid': _vm.submitted && _vm.$v.form.type.$invalid,
                        },attrs:{"id":"input-2","value-field":"value","text-field":"text","options":_vm.type},on:{"input":_vm.changeType},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}}),(_vm.submitted && !_vm.$v.form.type.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Type is required. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"col-6"},[_c('label',{attrs:{"for":"date"}},[_vm._v("expire slider date time "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-input',{class:{
                          'is-invalid':
                            _vm.submitted && _vm.$v.form.expire_slider.$invalid,
                        },attrs:{"id":"date","type":"datetime-local","min":_vm.disabledDates()},model:{value:(_vm.form.expire_slider),callback:function ($$v) {_vm.$set(_vm.form, "expire_slider", $$v)},expression:"form.expire_slider"}}),(_vm.submitted && !_vm.$v.form.expire_slider.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Slider Expired Date is required. ")]):_vm._e()],1),(
                        _vm.form.type != null &&
                        _vm.form.type != 'home' &&
                        _vm.form.type != 'external'
                      )?[_c('b-form-group',{staticClass:"col-6",attrs:{"label":_vm.form.type == 'partner'
                            ? 'Partner'
                            : _vm.form.type == 'partner_division'
                            ? 'Partner Division'
                            : _vm.form.type == 'community'
                            ? 'Community'
                            : '',"label-for":"type2"}},[(
                            _vm.form.type == 'partner' ||
                            _vm.form.type == 'partner_division' ||
                            _vm.form.type == 'community'
                          )?[_c('multiselect',{class:{
                              'is-invalid':
                                _vm.submitted && _vm.$v.form.type_id.$invalid,
                            },attrs:{"id":"type2","options":_vm.getType,"multiple":false,"track-by":"id","label":"title","placeholder":"Type here to search"},on:{"search-change":_vm.fetchType},model:{value:(_vm.form.type_id),callback:function ($$v) {_vm.$set(_vm.form, "type_id", $$v)},expression:"form.type_id"}},[_c('span',{attrs:{"slot":"noOptions"},slot:"noOptions"},[_vm._v(" Type here to search ")])]),(_vm.submitted && !_vm.$v.form.type_id.required)?_c('div',{staticClass:"invalid-feedback"},[(_vm.form.type == 'partner')?_c('span',[_vm._v("Partner is required when Type is Partner")]):(_vm.form.type == 'partner_division')?_c('span',[_vm._v("Partner Division is required when Type is Partner Division")]):(_vm.form.type == 'community')?_c('span',[_vm._v("Community is required when Type is Community")]):_vm._e()]):_vm._e()]:_vm._e()],2)]:_vm._e()],2),_c('div',{staticClass:"row"},[_c('b-form-group',{staticClass:"col-6",attrs:{"label":"Action Type","label-for":"act"}},[_c('b-form-select',{attrs:{"id":"act","options":_vm.actionType,"value-field":"value","text-field":"text"},on:{"change":_vm.changeActionType},model:{value:(_vm.form.action_type),callback:function ($$v) {_vm.$set(_vm.form, "action_type", $$v)},expression:"form.action_type"}})],1),(
                        _vm.form.action_type != null &&
                        _vm.form.action_type != 'none' &&
                        _vm.form.action_type != 'external'
                      )?[_c('b-form-group',{staticClass:"col-6",attrs:{"id":"input-group-3","label":_vm.form.action_type == 'expert'
                            ? 'Action ID : Expert'
                            : _vm.form.action_type == 'video'
                            ? 'Action ID : Video'
                            : _vm.form.action_type == 'live_event'
                            ? 'Action ID : Live Event'
                            : _vm.form.action_type == 'forum'
                            ? 'Action ID : Partner Division'
                            : _vm.form.action_type == 'news_letter'
                            ? 'Action ID : Newsletter'
                            : _vm.form.action_type == 'series'
                            ? 'Action ID : Series'
                            : _vm.form.action_type == 'news_article'
                            ? 'Action ID : Article'
                            : '',"label-for":"input-3"}},[(_vm.form.action_type == 'news_article')?[_c('multiselect',{class:{
                              'is-invalid':
                                _vm.submitted && _vm.$v.form.action_id.$invalid,
                            },attrs:{"id":"input-3","options":_vm.getArticles,"multiple":false,"track-by":"id","label":"card_name","placeholder":"Type here to search"},on:{"search-change":_vm.fetchArticle},model:{value:(_vm.form.action_id),callback:function ($$v) {_vm.$set(_vm.form, "action_id", $$v)},expression:"form.action_id"}},[_c('span',{attrs:{"slot":"noOptions"},slot:"noOptions"},[_vm._v(" Type here to search ")])]),(_vm.submitted && !_vm.$v.form.action_id.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" News Article is required when Action Type is News Article. ")]):_vm._e()]:(_vm.form.action_type == 'expert')?[_c('multiselect',{class:{
                              'is-invalid':
                                _vm.submitted && _vm.$v.form.action_id.$invalid,
                            },attrs:{"id":"input-3","options":_vm.getExperts,"multiple":false,"track-by":"id","label":"name","placeholder":"Type here to search"},on:{"search-change":_vm.fetchExpert},model:{value:(_vm.form.action_id),callback:function ($$v) {_vm.$set(_vm.form, "action_id", $$v)},expression:"form.action_id"}},[_c('span',{attrs:{"slot":"noOptions"},slot:"noOptions"},[_vm._v(" Type here to search ")])]),(_vm.submitted && !_vm.$v.form.action_id.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Expert is required when Action Type is Expert. ")]):_vm._e()]:_vm._e(),(_vm.form.action_type == 'video')?[_c('multiselect',{class:{
                              'is-invalid':
                                _vm.submitted && _vm.$v.form.action_id.$invalid,
                            },attrs:{"id":"input-3","options":_vm.getVideos,"multiple":false,"track-by":"id","label":"title","placeholder":"Type here to search"},on:{"search-change":_vm.fetchVideo},model:{value:(_vm.form.action_id),callback:function ($$v) {_vm.$set(_vm.form, "action_id", $$v)},expression:"form.action_id"}},[_c('span',{attrs:{"slot":"noOptions"},slot:"noOptions"},[_vm._v(" Type here to search ")])]),(_vm.submitted && !_vm.$v.form.action_id.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Video is required when Action Type is Video. ")]):_vm._e()]:(_vm.form.action_type == 'live_event')?[_c('multiselect',{class:{
                              'is-invalid':
                                _vm.submitted && _vm.$v.form.action_id.$invalid,
                            },attrs:{"id":"input-3","options":_vm.getLiveEvents,"multiple":false,"track-by":"id","label":"title","placeholder":"Type here to search"},on:{"search-change":_vm.fetchLiveEvent},model:{value:(_vm.form.action_id),callback:function ($$v) {_vm.$set(_vm.form, "action_id", $$v)},expression:"form.action_id"}},[_c('span',{attrs:{"slot":"noOptions"},slot:"noOptions"},[_vm._v(" Type here to search ")])]),(_vm.submitted && !_vm.$v.form.action_id.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Live Event is required when Action Type is Live Event. ")]):_vm._e()]:(_vm.form.action_type == 'forum')?[_c('multiselect',{class:{
                              'is-invalid':
                                _vm.submitted && _vm.$v.form.action_id.$invalid,
                            },attrs:{"id":"input-3","options":_vm.getForums,"multiple":false,"track-by":"id","label":"title","placeholder":"Type here to search"},on:{"search-change":_vm.fetchForum},model:{value:(_vm.form.action_id),callback:function ($$v) {_vm.$set(_vm.form, "action_id", $$v)},expression:"form.action_id"}},[_c('span',{attrs:{"slot":"noOptions"},slot:"noOptions"},[_vm._v(" Type here to search ")])]),(_vm.submitted && !_vm.$v.form.action_id.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Partner Division is required when Action Type is Partner Division. ")]):_vm._e()]:(_vm.form.action_type == 'series')?[_c('multiselect',{class:{
                              'is-invalid':
                                _vm.submitted && _vm.$v.form.action_id.$invalid,
                            },attrs:{"id":"input-3","options":_vm.getSeries,"multiple":false,"track-by":"id","label":"title","placeholder":"Type here to search"},on:{"search-change":_vm.fetchSeries},model:{value:(_vm.form.action_id),callback:function ($$v) {_vm.$set(_vm.form, "action_id", $$v)},expression:"form.action_id"}},[_c('span',{attrs:{"slot":"noOptions"},slot:"noOptions"},[_vm._v(" Type here to search ")])]),(_vm.submitted && !_vm.$v.form.action_id.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Series is required when Action Type is Series. ")]):_vm._e()]:(_vm.form.action_type == 'news_letter')?[_c('multiselect',{class:{
                              'is-invalid':
                                _vm.submitted && _vm.$v.form.action_id.$invalid,
                            },attrs:{"id":"input-3","options":_vm.getNewsletters,"multiple":false,"track-by":"id","label":"title","placeholder":"Type here to search"},on:{"search-change":_vm.fetchNewsletter},model:{value:(_vm.form.action_id),callback:function ($$v) {_vm.$set(_vm.form, "action_id", $$v)},expression:"form.action_id"}},[_c('span',{attrs:{"slot":"noOptions"},slot:"noOptions"},[_vm._v(" Type here to search ")])]),(_vm.submitted && !_vm.$v.form.action_id.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Newsletter is required when Action Type is Newsletter. ")]):_vm._e()]:_vm._e()],2)]:(_vm.form.action_type == 'external')?[_c('b-form-group',{staticClass:"col-6",attrs:{"label-for":"input-group-4","label":_vm.form.action_type == 'external'
                            ? 'Action ID : External'
                            : ''}},[_c('b-form-input',{class:{
                            'is-invalid':
                              _vm.submitted && _vm.$v.form.action_id.$invalid,
                          },attrs:{"id":"input-group-4","placeholder":"Enter External link"},model:{value:(_vm.form.action_id),callback:function ($$v) {_vm.$set(_vm.form, "action_id", $$v)},expression:"form.action_id"}}),(_vm.submitted && !_vm.$v.form.action_id.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" External Link is required when Action Type is External Website. ")]):_vm._e()],1)]:_vm._e()],2),_c('div',{staticClass:"row"},[_c('b-form-group',{staticClass:"col-4",attrs:{"id":"input-group-4"}},[_c('label',{attrs:{"for":"input-4"}},[_vm._v("Thumbnail Image [Upload Max File Size : 2MB] "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-file',{ref:"image",class:{
                          'is-invalid': _vm.submitted && _vm.$v.image.$invalid,
                        },attrs:{"id":"input-4","accept":"image/*","placeholder":"Choose a file or drop it here..."},on:{"change":function($event){return _vm.readFile($event, 'image')}}}),(_vm.$route.name == 'edit-slider' && _vm.edit.image_url)?[_c('img',{staticStyle:{"object-fit":"contain","margin-top":"5px"},attrs:{"src":_vm.edit.image_url,"width":"128px","height":"128px"}})]:_vm._e(),(_vm.image_url)?[_c('img',{ref:"image_url",staticStyle:{"object-fit":"contain","margin-top":"5px"},attrs:{"src":_vm.image_url,"width":"128px","height":"128px"}})]:_vm._e(),(_vm.submitted && !_vm.$v.image.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Thumbnail Image is required. ")]):_vm._e()],2),_c('b-form-group',{staticClass:"col-4",attrs:{"id":"input-group-5","label":"Android Image [Upload Max File Size : 2MB]","label-for":"input-5"}},[_c('b-form-file',{ref:"android_image",attrs:{"id":"input-5","accept":"image/*","placeholder":"Choose a file or drop it here..."},on:{"change":function($event){return _vm.readFile($event, 'android_image')}}}),(
                          _vm.$route.name == 'edit-slider' &&
                          _vm.edit.android_image_url
                        )?[_c('img',{staticStyle:{"object-fit":"contain","margin-top":"5px"},attrs:{"src":_vm.edit.android_image_url,"width":"128px","height":"128px"}})]:_vm._e(),(_vm.android_image_url)?[_c('img',{ref:"android_image_url",staticStyle:{"object-fit":"contain","margin-top":"5px"},attrs:{"src":_vm.android_image_url,"width":"128px","height":"128px"}})]:_vm._e()],2),_c('b-form-group',{staticClass:"col-4",attrs:{"id":"input-group-6","label":"IOS Image [Upload Max File Size : 2MB]","label-for":"input-6"}},[_c('b-form-file',{ref:"ios_image",attrs:{"id":"input-6","accept":"image/*","placeholder":"Choose a file or drop it here..."},on:{"change":function($event){return _vm.readFile($event, 'ios_image')}}}),(
                          _vm.$route.name == 'edit-slider' && _vm.edit.ios_image_url
                        )?[_c('img',{staticStyle:{"object-fit":"contain","margin-top":"5px"},attrs:{"src":_vm.edit.ios_image_url,"width":"128px","height":"128px"}})]:_vm._e(),(_vm.ios_image_url)?[_c('img',{ref:"ios_image_url",staticStyle:{"object-fit":"contain","margin-top":"5px"},attrs:{"src":_vm.ios_image_url,"width":"128px","height":"128px"}})]:_vm._e()],2)],1)],1),_c('tab-content',{attrs:{"title":"Add Tags","icon":"fa fa-tag"}},[_c('div',{staticClass:"row"},[_c('b-form-group',{staticClass:"col-6"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center mb-1"},[_c('label',{staticClass:"mb-0",attrs:{"for":"comm"}},[_vm._v("Speciality "),_c('span',{staticStyle:{"color":"red"}},[_vm._v(" *")])]),_c('b-form-checkbox',{attrs:{"button":"","button-variant":"info","size":"sm"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[(_vm.status)?[_vm._v("Unselect All")]:[_vm._v("Select All")]],2)],1),_c('multiselect',{class:{
                          'is-invalid':
                            _vm.store && _vm.$v.form.speciality_selected.$invalid,
                        },attrs:{"id":"comm","options":_vm.speciality,"multiple":true,"track-by":"id","label":"title","placeholder":"Type here to search"},on:{"search-change":_vm.fetchSpeciality},model:{value:(_vm.form.speciality_selected),callback:function ($$v) {_vm.$set(_vm.form, "speciality_selected", $$v)},expression:"form.speciality_selected"}},[_c('span',{attrs:{"slot":"noOptions"},slot:"noOptions"},[_vm._v(" Type here to search ")])]),(_vm.store && !_vm.$v.form.community_selected.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Community is required. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"col-6",attrs:{"label":"Country","label-for":"input-multi"}},[_c('multiselect',{attrs:{"id":"input-multi","options":_vm.allCountryWithAll,"multiple":true,"track-by":"id","placeholder":"Type here to search","label":"name"},on:{"search-change":_vm.fetchCountry},model:{value:(_vm.form.country),callback:function ($$v) {_vm.$set(_vm.form, "country", $$v)},expression:"form.country"}},[_c('span',{attrs:{"slot":"noOptions"},slot:"noOptions"},[_vm._v("Type here to search")])])],1)],1),_c('b-form-group',{attrs:{"label":"Enter Free tags","label-for":"tags-separators"}},[_c('b-form-tags',{attrs:{"input-id":"tags-separators","tag-variant":"primary","tag-pills":"","separator":" ","placeholder":"Enter new tags separated by space and enter"},model:{value:(_vm.form.tags),callback:function ($$v) {_vm.$set(_vm.form, "tags", $$v)},expression:"form.tags"}})],1),_c('div',{staticClass:"row"},[_c('b-form-group',{staticClass:"col-6 position-relative",attrs:{"id":"input-group-7","label":"Meta Title","label-for":"input-7"}},[_c('b-form-input',{attrs:{"id":"input-7","maxlength":250,"placeholder":"Enter Meta Title"},model:{value:(_vm.form.meta_title),callback:function ($$v) {_vm.$set(_vm.form, "meta_title", $$v)},expression:"form.meta_title"}}),_c('div',{staticClass:"text-right"},[(_vm.form.meta_title)?_c('p',{staticClass:"badge position-absolute",class:{
                            'badge-success': _vm.form.meta_title.length !== 250,
                            'badge-danger': _vm.form.meta_title.length === 250,
                          },staticStyle:{"top":"4px","right":"13px"}},[_vm._v(" You typed "+_vm._s(_vm.form.meta_title.length)+" out of 250 chars. ")]):_vm._e()])],1),_c('b-form-group',{staticClass:"col-6 position-relative",attrs:{"id":"input-group-9","label":"Meta Keywords","label-for":"input-9"}},[_c('b-form-input',{attrs:{"id":"input-9","maxlength":250,"placeholder":"Enter Meta Keywords"},model:{value:(_vm.form.meta_keywords),callback:function ($$v) {_vm.$set(_vm.form, "meta_keywords", $$v)},expression:"form.meta_keywords"}}),_c('div',{staticClass:"text-right"},[(_vm.form.meta_keywords)?_c('p',{staticClass:"badge position-absolute",class:{
                            'badge-success':
                              _vm.form.meta_keywords.length !== 250,
                            'badge-danger': _vm.form.meta_keywords.length === 250,
                          },staticStyle:{"top":"4px","right":"13px"}},[_vm._v(" You typed "+_vm._s(_vm.form.meta_keywords.length)+" out of 250 chars. ")]):_vm._e()])],1)],1),_c('b-form-group',{staticClass:"position-relative",attrs:{"id":"input-group-8","label":"Meta Description","label-for":"input-8"}},[_c('b-form-textarea',{attrs:{"id":"input-8","maxlength":240,"placeholder":"Enter Meta Description","rows":"3"},model:{value:(_vm.form.meta_desc),callback:function ($$v) {_vm.$set(_vm.form, "meta_desc", $$v)},expression:"form.meta_desc"}}),_c('div',{staticClass:"text-right"},[(_vm.form.meta_desc)?_c('p',{staticClass:"badge position-absolute",class:{
                          'badge-success': _vm.form.meta_desc.length !== 240,
                          'badge-danger': _vm.form.meta_desc.length === 240,
                        },staticStyle:{"top":"4px","right":"13px"}},[_vm._v(" You typed "+_vm._s(_vm.form.meta_desc.length)+" out of 240 chars. ")]):_vm._e()])],1)],1)],1)],1)])])])]),_c('b-tab',{attrs:{"title":"Indonesia"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('b-form-group',[_c('label',{attrs:{"for":"translation-title"}},[_vm._v("Slider Title")]),_c('b-form-input',{attrs:{"id":"translation-title","placeholder":"Enter Slider Title"},model:{value:(_vm.form.translation.indonesia.title),callback:function ($$v) {_vm.$set(_vm.form.translation.indonesia, "title", $$v)},expression:"form.translation.indonesia.title"}})],1),_c('b-form-group',{attrs:{"label":"Tell Us More About The Slider","label-for":"translation-desc"}},[_c('ckeditor',{attrs:{"editor":_vm.editor},model:{value:(_vm.form.translation.indonesia.description),callback:function ($$v) {_vm.$set(_vm.form.translation.indonesia, "description", $$v)},expression:"form.translation.indonesia.description"}})],1),_c('div',{staticClass:"row"},[_c('b-form-group',{staticClass:"col-4"},[_c('label',{attrs:{"for":"translation-thumbnail"}},[_vm._v("Thumbnail Image [Upload Max File Size : 2MB]")]),_c('b-form-file',{ref:"indonesia_image",attrs:{"id":"translation-thumbnail","accept":"image/*","placeholder":"Choose a file or drop it here..."},on:{"change":function($event){return _vm.readFile($event, 'indonesia_image')}}}),(
                      _vm.$route.name == 'edit-slider' && _vm.edit.indonesia_image_url
                    )?[_c('img',{staticStyle:{"object-fit":"contain","margin-top":"5px"},attrs:{"src":_vm.edit.indonesia_image_url,"width":"128px","height":"128px"}})]:_vm._e(),(_vm.indonesia_image_url)?[_c('img',{ref:"indonesia_image_url",staticStyle:{"object-fit":"contain","margin-top":"5px"},attrs:{"src":_vm.indonesia_image_url,"width":"128px","height":"128px"}})]:_vm._e()],2),_c('b-form-group',{staticClass:"col-4",attrs:{"label":"Android Image [Upload Max File Size : 2MB]","label-for":"translation-android"}},[_c('b-form-file',{ref:"indonesia_android_image",attrs:{"id":"translation-android","accept":"image/*","placeholder":"Choose a file or drop it here..."},on:{"change":function($event){return _vm.readFile($event, 'indonesia_android_image')}}}),(
                      _vm.$route.name == 'edit-slider' &&
                      _vm.edit.indonesia_android_image_url
                    )?[_c('img',{staticStyle:{"object-fit":"contain","margin-top":"5px"},attrs:{"src":_vm.edit.indonesia_android_image_url,"width":"128px","height":"128px"}})]:_vm._e(),(_vm.indonesia_android_image_url)?[_c('img',{ref:"indonesia_android_image_url",staticStyle:{"object-fit":"contain","margin-top":"5px"},attrs:{"src":_vm.indonesia_android_image_url,"width":"128px","height":"128px"}})]:_vm._e()],2),_c('b-form-group',{staticClass:"col-4",attrs:{"label":"IOS Image [Upload Max File Size : 2MB]","label-for":"translation-ios"}},[_c('b-form-file',{ref:"indonesia_ios_image",attrs:{"id":"translation-ios","accept":"image/*","placeholder":"Choose a file or drop it here..."},on:{"change":function($event){return _vm.readFile($event, 'indonesia_ios_image')}}}),(
                      _vm.$route.name == 'edit-slider' &&
                      _vm.edit.indonesia_ios_image_url
                    )?[_c('img',{staticStyle:{"object-fit":"contain","margin-top":"5px"},attrs:{"src":_vm.edit.indonesia_ios_image_url,"width":"128px","height":"128px"}})]:_vm._e(),(_vm.indonesia_ios_image_url)?[_c('img',{ref:"indonesia_ios_image_url",staticStyle:{"object-fit":"contain","margin-top":"5px"},attrs:{"src":_vm.indonesia_ios_image_url,"width":"128px","height":"128px"}})]:_vm._e()],2)],1),_c('div',{staticClass:"row"},[_c('b-form-group',{staticClass:"col-6",attrs:{"label":"Meta Title","label-for":"translation-meta-title"}},[_c('b-form-input',{attrs:{"id":"translation-meta-title","placeholder":"Enter Meta Title"},model:{value:(_vm.form.translation.indonesia.meta_title),callback:function ($$v) {_vm.$set(_vm.form.translation.indonesia, "meta_title", $$v)},expression:"form.translation.indonesia.meta_title"}})],1),_c('b-form-group',{staticClass:"col-6",attrs:{"label":"Meta Keywords","label-for":"translation-meta-keywords"}},[_c('b-form-input',{attrs:{"id":"translation-meta-keywords","placeholder":"Enter Meta Keywords"},model:{value:(_vm.form.translation.indonesia.meta_keywords),callback:function ($$v) {_vm.$set(_vm.form.translation.indonesia, "meta_keywords", $$v)},expression:"form.translation.indonesia.meta_keywords"}})],1)],1),_c('b-form-group',{staticClass:"position-relative",attrs:{"label":"Meta Description","label-for":"translation-meta-desc"}},[_c('b-form-textarea',{attrs:{"id":"translation-meta-desc","placeholder":"Enter Meta Description","rows":"3"},model:{value:(_vm.form.translation.indonesia.meta_desc),callback:function ($$v) {_vm.$set(_vm.form.translation.indonesia, "meta_desc", $$v)},expression:"form.translation.indonesia.meta_desc"}})],1)],1)])])])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }